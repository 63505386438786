import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { AvoTypes } from '@thriveglobal/thrive-web-tracking'
import { useTrackAssessmentSurveySubmit } from '../../../../tracking'
import {
    AssessmentAttemptSuccess,
    AssessmentItem,
    ResponseInput,
    useSubmitResponseMutation
} from '../../../../graphql/generated/autogenerated'

/**
 * Custom hook for handling assessment submission.
 *
 * @param {AssessmentAttemptSuccess} assessmentAttempt - The assessment attempt object containing metadata about the attempt.
 * @returns {Function} - A function to submit the user's response to the current assessment item.
 */
const useAssessmentSubmission = (
    assessmentAttempt: AssessmentAttemptSuccess
) => {
    const trackAssessmentSurveySubmit = useTrackAssessmentSurveySubmit()
    const [submitResponseMutation] = useSubmitResponseMutation()
    const { timezone } = useAppSelector((state) => state.settings)

    /**
     * Submits the user's response to the current assessment item and tracks the submission.
     *
     * @async
     * @param {AssessmentItem} currentAssessmentItem - The current assessment item being answered.
     * @param {AvoTypes.ActivityTypeValueType} activityType - The type of activity being tracked.
     * @param {ResponseInput} [responseInput] - The user's response input (optional).
     * @returns {Promise<Object>} - The response object returned by the GraphQL mutation.
     */
    return async (
        currentAssessmentItem: AssessmentItem,
        activityType: AvoTypes.ActivityTypeValueType,
        responseInput?: ResponseInput
    ) => {
        const { id: assessmentItemId, questionText } = currentAssessmentItem
        const { assessmentAttemptId } = assessmentAttempt
        const time = new Date()
        const response = await submitResponseMutation({
            variables: {
                input: {
                    assessmentAttemptId,
                    assessmentItemId,
                    questionText,
                    response: responseInput,
                    userTime: { time, timezone }
                }
            }
        })

        trackAssessmentSurveySubmit({
            activityType,
            assessmentAttempt,
            assessmentItem: currentAssessmentItem,
            selectedResponse: responseInput
        })

        return response
    }
}

export default useAssessmentSubmission
