import { ROUTES } from '../routes'
import {
    AuthState,
    captureException,
    captureMessage,
    store
} from '@thriveglobal/thrive-web-core'
import { selectIsTokenExpired } from '@thriveglobal/thrive-web-auth-core'
import { useEffect } from 'react'
import { getPostLoginRedirectPath } from '../utils/redirectUtils'

export function isUserAlreadyLoggedIn() {
    const authStore = store.getState().auth
    const isTokenExpired = selectIsTokenExpired(authStore as AuthState)
    const validLogin = authStore.isLoggedIn && !isTokenExpired
    return validLogin
}

export function useIsUserAlreadyLoggedIn() {
    return isUserAlreadyLoggedIn()
}

export function redirectToAppIfLoggedIn() {
    try {
        const validLogin = isUserAlreadyLoggedIn()
        const isTokenRevokeRoute = window.location.href.includes(
            ROUTES.TOKEN_REVOKE
        )

        const isSessionEndedRoute = window.location.href.includes(
            ROUTES.LOGOUT_CONFIRMATION
        )

        const isReferralSignupRoute = window.location.href.includes(
            ROUTES.REFERRAL_SIGNUP
        )

        const isMagicLinkRoute = window.location.href.includes(
            ROUTES.MAGIC_LINK
        )

        if (
            validLogin &&
            !isTokenRevokeRoute &&
            !isSessionEndedRoute &&
            !isReferralSignupRoute &&
            !isMagicLinkRoute
        ) {
            const redirectUrl = getPostLoginRedirectPath()
            captureMessage(
                `User already logged in, redirecting to: ${redirectUrl}`
            )
            window.location.assign(redirectUrl)
        }
    } catch (error) {
        captureException(error, {
            message: 'Unable to redirect into the logged in app'
        })
    }
}

export function useRedirectToAppIfLoggedIn() {
    useEffect(() => {
        redirectToAppIfLoggedIn()
    }, [])
}
