import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { ComponentType } from 'react'

export default function withTeamsComponentsEnabled<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const enableMsTeamsComponents = useIsFeatureEnabled(
            'enableMsTeamsComponents',
            false,
            true
        )

        if (!enableMsTeamsComponents) {
            return null
        }

        return <Component {...props} />
    }
}
