import { useState, useEffect, useMemo } from 'react'
import {
    AssessmentAttemptSuccess,
    AssessmentItem
} from '../../../../graphql/generated/autogenerated'
import { getAssessmentProgressCount } from '../../../utils'

/**
 * Hook to manage the progress of an assessment, including step navigation and state.
 *
 * @param {AssessmentAttemptSuccess} assessmentAttempt - The current assessment attempt data.
 * @param {AssessmentItem[]} assessmentItems - Array of assessment items (questions).
 * @param {boolean} retainProgress - Flag to retain progress based on previous responses.
 *
 * @returns {{
 *   step: number; // The current step number.
 *   currentItem: AssessmentItem | undefined; // The current assessment item.
 *   nextStep: () => void; // Function to move to the next step.
 *   prevStep: () => void; // Function to move to the previous step.
 *   isFirstStep: boolean; // True if the current step is the first step.
 *   isFinalStep: boolean; // True if the current step is the final step.
 *   totalSteps: nunmber; // cound of total steps for assessment
 * }}
 */
const useAssessmentProgress = (
    assessmentAttempt: AssessmentAttemptSuccess,
    assessmentItems: AssessmentItem[],
    retainProgress: boolean
) => {
    const [step, setStep] = useState<number>(1)
    const [currentAssessmentItem, setCurrentAssessmentItem] = useState<
        AssessmentItem | undefined
    >()

    useEffect(() => {
        if (retainProgress && assessmentAttempt?.givenResponses?.length > 0) {
            setStep(
                getAssessmentProgressCount(assessmentAttempt.givenResponses) + 1
            )
        }
    }, [retainProgress, assessmentAttempt?.givenResponses])

    useEffect(() => {
        setCurrentAssessmentItem(assessmentItems[step - 1])
    }, [step, assessmentItems])

    const nextStep = () => {
        if (step < assessmentItems.length) {
            setStep((prev) => prev + 1)
        }
    }

    const prevStep = () => {
        if (step > 1) {
            setStep((prev) => prev - 1)
        }
    }

    const isFirstStep = useMemo(() => step === 1, [step])

    const isFinalStep = useMemo(
        () => step === assessmentItems.length,
        [step, assessmentItems.length]
    )

    return {
        step,
        totalSteps: assessmentItems?.length || 0,
        currentAssessmentItem,
        nextStep,
        prevStep,
        isFirstStep,
        isFinalStep
    }
}

export default useAssessmentProgress
