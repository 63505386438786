import { Checkbox, Stack } from '@mui/material'
import {
    CheckboxConsent,
    CoreTypography,
    LeafPrivacyPolicyLink,
    LeafToSLink
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, ReactNode, useMemo } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

const messages = defineMessages({
    tosAcceptanceDialogBody: {
        defaultMessage:
            'Please agree to the Privacy Policy and Terms of Service before proceeding with Thrive.',
        description:
            'Please agree to the Privacy Policy and Terms of Service before proceeding with Thrive.'
    },
    tosApacAcceptance: {
        defaultMessage:
            'By providing your consent, you agree to the collection and use of your personal information to authenticate your account, deliver and improve Thrive’s services, and communicate with you. Personal data may include your name, email address, engagement, and, if applicable, any activity data from connected devices. Your data will be retained only as long as necessary to fulfill these purposes. You may withdraw consent or request changes to your data at any time.',
        description: 'description of what clicking accept grants'
    },
    agreeToToS: {
        defaultMessage:
            'I agree to the <privacyPolicy>Privacy Policy</privacyPolicy> and the <termsOfService>Terms of Service</termsOfService>',
        description: 'Message to accept the Privacy Policy and Terms of Service'
    }
})

const APAC_COUNTRIES = ['KR', 'VN']

export interface ToSAcceptanceProps extends JSX.IntrinsicAttributes {
    country?: string
    onToSAccepted: (toSAccepted: boolean) => void
}

export const ToSAcceptance: React.FC<ToSAcceptanceProps> = ({
    country,
    onToSAccepted
}) => {
    const { formatMessage } = useIntl()

    const countryIsAPAC = useMemo(
        () => APAC_COUNTRIES.includes(country ?? ''),
        [country]
    )

    return (
        <Stack>
            <CoreTypography pb={1} variant="body1">
                {formatMessage(
                    countryIsAPAC
                        ? messages.tosApacAcceptance
                        : messages.tosAcceptanceDialogBody
                )}
            </CoreTypography>

            <Stack direction="row" alignItems="center">
                <CheckboxConsent
                    onAccepted={onToSAccepted}
                    country={country ?? 'KR'}
                />
            </Stack>

            <CoreTypography variant="caption" sx={{ pt: 1 }}>
                <FormattedMessage
                    defaultMessage={`After providing the necessary consent, click "Agree and get started" to access Thrive’s services.`}
                    description="description of what clicking accept grants"
                />
            </CoreTypography>
        </Stack>
    )
}

export default memo(ToSAcceptance)
