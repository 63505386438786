import withErrorComponentBoundary from '../../../hooks/withErrorComponentBoundary/withErrorComponentBoundary'
import withTeamsComponentsEnabled from '../../../hooks/withTeamsComponentsEnabled/withTeamsComponentsEnabled'
import ShareContentOnTeams from './ShareContentOnTeams'
import ShareContentOnTeamsBranchLink from './ShareContentOnTeamsBranchLink'

export const ShareContentOnTeamsWithFallback = withErrorComponentBoundary(
    'ShareContentOnTeams',
    withTeamsComponentsEnabled(ShareContentOnTeams)
)
export const ShareContentOnTeamsBranchLinkWithFallback =
    withErrorComponentBoundary(
        'ShareContentOnTeamsBranchLink',
        withTeamsComponentsEnabled(ShareContentOnTeamsBranchLink)
    )

export default ShareContentOnTeams
