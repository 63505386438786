import {
    useCrossAppNavigation,
    useGenerateTeamsDeepLink
} from '@thriveglobal/thrive-web-core'
import { useEffect, useState } from 'react'
import PageState from '../components/elements/PageState/PageState'
import { ThriveFullscreenLoading } from '@thriveglobal/thrive-web-leafkit'
import { updateUtmSourceToMSTeams } from '../utils'

const Deeplink: React.FC = () => {
    const navigate = useCrossAppNavigation()
    const generateDeeplink = useGenerateTeamsDeepLink()
    const [error, setError] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        try {
            if (params.has('redirect')) {
                const redirect = updateUtmSourceToMSTeams(
                    params.get('redirect')
                )

                navigate(generateDeeplink(redirect).toString())
            } else {
                setError(true)
            }
        } catch (_) {
            setError(true)
        }
    }, [generateDeeplink, navigate])

    return (
        <PageState
            loading={true}
            error={error}
            loadingComponent={<ThriveFullscreenLoading />}
        />
    )
}

export default Deeplink
