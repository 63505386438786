import React, {
    lazy,
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material'
import {
    addUserToSentry,
    captureException,
    DD
} from '@thriveglobal/thrive-web-core'
import { LoginCallback } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { addEmailDomainToLoggingPlatform } from '../utils/setEmailDomainInLoggingPlatform'
import { getOrGenerateUserId } from '../utils/getTempUserId'

const CompanySignup = lazy(() => import('../pages/CompanySignup'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))
const ResetPassword = lazy(() => import('../pages/ResetPassword'))
const LandingPage = lazy(() => import('../pages/LandingPage/LandingPage'))
const LandingPageSelect = lazy(
    () => import('../pages/LandingPageMagicLinkSelect/LandingPageSelect')
)
const EmailSignup = lazy(() => import('../pages/Signup/EmailSignup'))
const TokenRevoke = lazy(() => import('../POC/pages/TokenRevoke'))
const SignUpMethod = lazy(() => import('../pages/Signup/SignUpMethod'))
const TokenExchange = lazy(() => import('../POC/pages/TokenExchange'))
const LogoutConfirmation = lazy(() => import('../pages/LogoutConfirmation'))
const ReferralSignup = lazy(
    () => import('../pages/ReferralSignup/ReferralSignup')
)
const MagicLinkPage = lazy(() => import('../pages/MagicLink/MagicLink'))

export const enum ROUTES {
    LANDING = '/login',
    LANDING_TRAILING = '/login/',
    LANDING_STEP_2 = '/login/select',
    LOGIN_CALLBACK = '/login/callback',
    EMAIL_SIGN_UP = '/login/signup/email',
    SIGN_UP_METHOD = '/login/signup/method',
    BRAND_SELECT = '/login/signup/brand',
    FORGOT_PASSWORD = '/login/forgot-password',
    RESET_PASSWORD = '/login/forgot-password-confirm',
    TOKEN_REVOKE = '/login/token/revoke',
    TOKEN_EXCHANGE = '/login/token/success',
    LOGOUT_CONFIRMATION = '/login/session-ended',
    MAGIC_LINK = '/login/magic',
    // Temporary for now
    IDENTITY_V2_SIGN_UP = '/login/identityV2/signup/email',
    REFERRAL_SIGNUP = '/login/signup/referral'
}

const LANDING_PAGE_ROUTES = [ROUTES.LANDING, ROUTES.LANDING_TRAILING]
const ALL_LANDING_PAGE_ROUTES = [
    ROUTES.LANDING,
    ROUTES.LANDING_TRAILING,
    ROUTES.LANDING_STEP_2
]

interface ISignInRoute {
    id: string
    exact: boolean
    path: Array<string> | string
    children: any
}

function getRoutes({
    acceptedTnC,
    referrerId,
    handleTnC,
    email,
    handleEmailChange,
    handleReferrer
}): Array<ISignInRoute> {
    return [
        {
            id: 'login-callback',
            exact: true,
            path: ROUTES.LOGIN_CALLBACK,
            children: () => (
                <Suspense fallback={<CircularProgress />}>
                    <LoginCallback
                        userAcceptedTnC={acceptedTnC}
                        referrerId={referrerId}
                    />
                </Suspense>
            )
        },
        {
            id: 'sign-up-brand-select',
            exact: true,
            path: ROUTES.BRAND_SELECT,
            children: () => (
                <DefaultLayout variant="light" redirect="login">
                    <Suspense fallback={<CircularProgress />}>
                        <CompanySignup
                            acceptedTnC={acceptedTnC}
                            setAcceptedTnC={handleTnC}
                            email={email}
                        />
                    </Suspense>
                </DefaultLayout>
            )
        },
        {
            id: 'sign-up-form',
            exact: true,
            path: `${ROUTES.EMAIL_SIGN_UP}/:encodedCompany`,
            children: () => (
                <Suspense fallback={<CircularProgress />}>
                    <EmailSignup
                        emailHint={email}
                        acceptedTnC={acceptedTnC}
                        setAcceptedTnC={handleTnC}
                    />
                </Suspense>
            )
        },
        {
            id: 'sign-up-company-methods',
            exact: true,
            path: `${ROUTES.SIGN_UP_METHOD}/:encodedCompany`,
            children: () => (
                <Suspense fallback={<CircularProgress />}>
                    <SignUpMethod
                        acceptedTnC={acceptedTnC}
                        setAcceptedTnC={handleTnC}
                    />
                </Suspense>
            )
        },
        {
            id: 'sign-up-forgot-password',
            exact: true,
            path: ROUTES.FORGOT_PASSWORD,
            children: () => (
                <DefaultLayout variant="dark" redirect="login">
                    <Suspense fallback={<CircularProgress />}>
                        <ForgotPassword onEmailChange={handleEmailChange} />
                    </Suspense>
                </DefaultLayout>
            )
        },
        {
            id: 'sign-up-reset-password',
            exact: true,
            path: ROUTES.RESET_PASSWORD,
            children: () => (
                <DefaultLayout variant="dark" redirect="login">
                    <Suspense fallback={<CircularProgress />}>
                        <ResetPassword email={email} />
                    </Suspense>
                </DefaultLayout>
            )
        },
        {
            id: 'token-exchange',
            exact: true,
            path: ROUTES.TOKEN_EXCHANGE,
            children: () => (
                <Suspense fallback={<CircularProgress />}>
                    <TokenExchange />
                </Suspense>
            )
        },
        {
            id: 'token-revoke',
            exact: true,
            path: ROUTES.TOKEN_REVOKE,
            children: () => (
                <Suspense fallback={<CircularProgress />}>
                    <TokenRevoke />
                </Suspense>
            )
        },
        {
            id: 'logout-confirm',
            exact: true,
            path: ROUTES.LOGOUT_CONFIRMATION,
            children: () => (
                <Suspense fallback={<CircularProgress />}>
                    <LogoutConfirmation />
                </Suspense>
            )
        },
        {
            id: 'referral-signup',
            exact: true,
            path: ROUTES.REFERRAL_SIGNUP,
            children: () => (
                <Suspense fallback={<CircularProgress />}>
                    <ReferralSignup
                        acceptedTnC={acceptedTnC}
                        setAcceptedTnC={handleTnC}
                        referrerId={referrerId}
                        setReferrerId={handleReferrer}
                    />
                </Suspense>
            )
        },
        {
            id: 'login-landings',
            exact: true,
            path: ALL_LANDING_PAGE_ROUTES,
            children: () => (
                <DefaultLayout variant="dark" redirect="signUp">
                    <Suspense fallback={<CircularProgress />}>
                        <Route
                            exact
                            path={LANDING_PAGE_ROUTES}
                            children={
                                <LandingPage
                                    onEmailChange={handleEmailChange}
                                    handleReferrer={handleReferrer}
                                />
                            }
                        />
                        <Route
                            exact
                            path={ROUTES.LANDING_STEP_2}
                            children={<LandingPageSelect email={email} />}
                        />
                    </Suspense>
                </DefaultLayout>
            )
        },
        {
            id: 'login-magic-link',
            exact: true,
            path: ROUTES.MAGIC_LINK,
            children: () => (
                <Suspense fallback={null}>
                    <MagicLinkPage />
                </Suspense>
            )
        },
        {
            id: 'login-redirects',
            exact: true,
            path: '/login/*',
            children: () => <Redirect to={ROUTES.LANDING} />
        }
    ]
}

export const TERMS_AND_CONDITIONS_KEY = 'Thrive-Accepted-Terms-and-conditions'
export const REFERRER_ID = 'referrerId'

export function getInitialTnCValue() {
    try {
        const accepted = globalThis.sessionStorage.getItem(
            TERMS_AND_CONDITIONS_KEY
        )
        if (accepted) {
            return true
        }
    } catch (error) {
        captureException(error, {
            message: 'Failed to get t&c flag from storage'
        })
    }
    return false
}

export function getInitialReferrerValue() {
    try {
        const referrerId = globalThis.sessionStorage.getItem(REFERRER_ID)
        return referrerId
    } catch (error) {
        return ''
    }
}

const Router: React.FC = () => {
    const theme = useTheme()
    const [email, setEmail] = useState<string>('')
    const [acceptedTnC, setAcceptedTnC] = useState<boolean>(
        getInitialTnCValue()
    )
    const [referrerId, setReferrerId] = useState<string>(
        getInitialReferrerValue()
    )

    const handleTnC = useCallback(
        (newValue) => {
            setAcceptedTnC(newValue)
            globalThis.sessionStorage.setItem(
                TERMS_AND_CONDITIONS_KEY,
                newValue
            )
        },
        [acceptedTnC, setAcceptedTnC]
    )

    const handleReferrer = useCallback(
        (newValue) => {
            setReferrerId(newValue)
            globalThis.sessionStorage.setItem(REFERRER_ID, newValue)
        },
        [referrerId, setReferrerId]
    )

    const handleEmailChange = (newEmail: string) => {
        setEmail(newEmail)
    }

    useEffect(() => {
        const userId = getOrGenerateUserId()
        addEmailDomainToLoggingPlatform()
        addUserToSentry(userId)
        DD.addUnAuthenticatedUserId(userId)
        return () => {
            // on cleanup of this function for unmount then we will remove the temp user from sentry
            addUserToSentry()
            DD.removeUnAuthenticatedUserId()
        }
    }, [])

    const routes = useMemo(() => {
        return getRoutes({
            acceptedTnC,
            referrerId,
            handleTnC,
            email,
            handleEmailChange,
            handleReferrer
        })
    }, [
        acceptedTnC,
        referrerId,
        handleTnC,
        email,
        handleEmailChange,
        handleReferrer
    ])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Switch>
                    {routes?.map((route) => {
                        return (
                            <Route
                                key={`route-${route.id}`}
                                exact={route.exact}
                                path={route.path}
                                children={route.children}
                            />
                        )
                    })}
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
