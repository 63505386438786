import { memo } from 'react'
import withErrorComponentBoundary from '../../../hooks/withErrorComponentBoundary/withErrorComponentBoundary'
import withTeamsComponentsEnabled from '../../../hooks/withTeamsComponentsEnabled/withTeamsComponentsEnabled'
import NavigationButton from './NavigationButton'

export const NavigationButtonWithFallback = withErrorComponentBoundary(
    'NavigationButton',
    withTeamsComponentsEnabled(NavigationButton)
)

export default memo(NavigationButtonWithFallback)
