import { Stack, StackProps, useTheme } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    privacyNotice: {
        defaultMessage: 'Your answers are always completely anonymous',
        description:
            "Notice that the user's responses will always be anonymous."
    }
})
const PrivacyNotice: React.FC<StackProps> = ({ ...props }) => {
    const { formatMessage } = useIntl()
    return (
        <Stack
            direction={'row'}
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            color={'text.primary'}
            gap={1}
            {...props}
        >
            <LeafIcon icon={'lock'} fontSize={'small'} />
            <CoreTypography variant={'body1'}>
                {formatMessage(messages.privacyNotice)}
            </CoreTypography>
        </Stack>
    )
}

export default PrivacyNotice
