import { useCallback } from 'react'
import { Avo, AvoTypes } from '@thriveglobal/thrive-web-tracking'
import {
    AssessmentAttemptSuccess,
    AssessmentItem,
    ResponseInput
} from '../graphql/generated/autogenerated'

/**
 * @typedef {Object} UseTrackAssessmentSurveySubmitProps
 * @property {AvoTypes.ActivityTypeValueType} activityType - The type of activity for the assessment.
 * @property {AssessmentAttemptSuccess} assessmentAttempt - The assessment attempt object containing details of the attempt.
 * @property {AssessmentItem} assessmentItem - The assessment item being submitted.
 * @property {ResponseInput} selectedResponse - The user's selected response input.
 */
type UseTrackAssessmentSurveySubmitProps = {
    activityType: AvoTypes.ActivityTypeValueType
    assessmentAttempt: AssessmentAttemptSuccess
    assessmentItem: AssessmentItem
    selectedResponse: ResponseInput
}

/**
 * Custom hook to track the submission of an assessment survey.
 *
 * @returns {function(UseTrackAssessmentSurveySubmitProps): void} A callback function that tracks the survey submission.
 *
 * @example
 * const trackSurveySubmit = useTrackAssessmentSurveySubmit();
 * trackSurveySubmit({
 *   activityType: 'someActivityType',
 *   assessmentAttempt: someAssessmentAttemptObject,
 *   assessmentItem: someAssessmentItemObject,
 *   selectedResponse: someSelectedResponseObject
 * });
 */
const useTrackAssessmentSurveySubmit = () => {
    return useCallback(
        ({
            assessmentItem,
            assessmentAttempt,
            selectedResponse,
            activityType
        }: UseTrackAssessmentSurveySubmitProps) => {
            Avo.surveySubmitted({
                featureType: 'assessment',
                activityType: activityType,
                assessmentId: assessmentAttempt.assessmentId,
                assessmentItemId: assessmentItem.id,
                assessmentAttemptId: assessmentAttempt.assessmentAttemptId,
                assessmentName: assessmentAttempt.name,
                assessmentVariant: assessmentAttempt.variant,
                isOnboarding: true,
                responseText: selectedResponse
                    ? selectedResponse.text
                    : undefined,
                responseValue: selectedResponse
                    ? selectedResponse.value
                    : undefined,
                dciCount: undefined,
                journey: undefined,
                journeyDay: undefined,
                journeyId: undefined,
                journeyLevel: undefined,
                npsScore: undefined,
                openAnswer: undefined,
                tabName: undefined,
                firstJourney: undefined,
                surveyLocation: undefined,
                messageQueueId: undefined,
                dispatchedDate: undefined,
                isSystemEvent: false
            })
        },
        []
    )
}

export default useTrackAssessmentSurveySubmit
