import { memo } from 'react'
import withErrorComponentBoundary from '../../../hooks/withErrorComponentBoundary/withErrorComponentBoundary'
import ToSAcceptanceModal from './ToSAcceptanceModal'

export const ToSAcceptanceModalWithFallback = withErrorComponentBoundary(
    'ToSAcceptanceModal',
    ToSAcceptanceModal
)

export default memo(ToSAcceptanceModalWithFallback)
