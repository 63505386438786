import { Button, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'
import React from 'react'

const messages = defineMessages({
    backButtonLabel: {
        defaultMessage: 'Back',
        description: 'Button label for going back to the previous page.'
    },
    nextButtonLabel: {
        defaultMessage: 'Next',
        description: 'Button label for proceeding to the next page.'
    },
    finishButtonLabel: {
        defaultMessage: 'Finish',
        description: 'Button label for the final question'
    }
})

/**
 * Props for the AssessmentActions component.
 *
 * @typedef {Object} AssessmentActionsProps
 * @property {boolean} isFinalStep - Indicates if the current step is the final step.
 * @property {boolean} isFirstStep - Indicates if the current step is the first step.
 * @property {() => void} handleBackClicked - Callback function to handle the "Back" button click event.
 * @property {() => void} handleNextClicked - Callback function to handle the "Next" or "Finish" button click event.
 */
interface AssessmentActionsProps {
    isFinalStep: boolean
    isFirstStep: boolean
    handleBackClicked: () => void
    handleNextClicked: () => void
}

/**
 * A component for rendering navigation buttons in an assessment flow.
 *
 * @param {AssessmentActionsProps} props - The props for the component.
 */
const AssessmentActions: React.FC<AssessmentActionsProps> = ({
    isFirstStep,
    isFinalStep,
    handleNextClicked,
    handleBackClicked
}) => {
    const { formatMessage } = useIntl()

    return (
        <Stack
            direction={'row'}
            justifyContent={'center'}
            my={2}
            gap={1}
            width={'100%'}
        >
            {!isFirstStep && (
                <Button onClick={handleBackClicked}>
                    <CoreTypography customVariant={'buttonNormal'}>
                        {formatMessage(messages.backButtonLabel)}
                    </CoreTypography>
                </Button>
            )}
            <Button variant={'contained'} onClick={() => handleNextClicked()}>
                <CoreTypography customVariant={'buttonNormal'}>
                    {formatMessage(
                        isFinalStep
                            ? messages.finishButtonLabel
                            : messages.nextButtonLabel
                    )}
                </CoreTypography>
            </Button>
        </Stack>
    )
}

export default AssessmentActions
