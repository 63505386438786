import { Skeleton, Stack } from '@mui/material'
import React from 'react'

/**
 * Props for the AssessmentSkeleton component.
 * @property {boolean} [loading] - Determines if the skeleton should be displayed.
 */
interface AssessmentSkeletonProps {
    loading?: boolean
}

/**
 * Displays a loading skeleton UI for assessments when the `loading` prop is true.
 *
 * @param {AssessmentSkeletonProps} props - The props for the component.
 */
const AssessmentSkeleton: React.FC<AssessmentSkeletonProps> = ({ loading }) => {
    if (!loading) return null

    return (
        <Stack mt={2} gap={1} display={'flex'} width={'100%'}>
            <Skeleton
                variant={'text'}
                sx={{ fontSize: '1.4rem' }}
                width={'80px'}
            />
            <Skeleton
                variant={'text'}
                sx={{ fontSize: '2rem' }}
                width={'33%'}
            />
            <Skeleton
                variant={'rounded'}
                sx={{ maxWidth: '100%' }}
                width={'100%'}
                height={'44px'}
            />
        </Stack>
    )
}

export default AssessmentSkeleton
