import { DialogActions, DialogContent, Skeleton } from '@mui/material'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useGetCountryCodeHeaderQuery } from '../../../../../graphql/generated/autogenerated'
import SaveToSAcceptance from '../SaveToSAcceptance/SaveToSAcceptance'
import ToSAcceptance from '../ToSAcceptance/ToSAcceptance'
import ConditionalSkeleton from '../../../../../components/elements/ConditionalSkeleton/ConditionalSkeleton'

const messages = defineMessages({
    tosAcceptanceDialogTitle: {
        defaultMessage: 'Our Privacy Policy and Terms of Service',
        description: 'Our Privacy Policy and Terms of Service'
    }
})

export interface ToSAcceptanceModalProps extends JSX.IntrinsicAttributes {
    open: boolean
    onAcceptToS: (success: boolean) => Promise<void>
}

export const ToSAcceptanceModal: React.FC<ToSAcceptanceModalProps> = ({
    open,
    onAcceptToS
}) => {
    const { formatMessage } = useIntl()
    const [toSAccepted, setToSAccepted] = useState<boolean>(false)
    const { loading, data } = useGetCountryCodeHeaderQuery()
    const country = useMemo(
        () => data?.identity?.user?.getCountryCodeHeader,
        [data]
    )

    return (
        <LeafDialog
            fullWidth
            open={open}
            hideCloseButton
            disableEscapeKeyDown
            dialogTitle={formatMessage(messages.tosAcceptanceDialogTitle)}
            sx={{
                zIndex: 9999
            }}
        >
            <DialogContent>
                {loading ? (
                    <Skeleton variant="rectangular" height={200} />
                ) : (
                    <ToSAcceptance
                        country={country}
                        onToSAccepted={setToSAccepted}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <ConditionalSkeleton showSkeleton={loading}>
                    <SaveToSAcceptance
                        color="primary"
                        disabled={!toSAccepted}
                        onSavedToSAcceptance={onAcceptToS}
                        size="large"
                        variant="contained"
                    >
                        <CoreTypography customVariant="buttonLarge">
                            <FormattedMessage
                                defaultMessage="Agree and get started"
                                description="Accept the Privacy Policy and Terms of Service"
                            />
                        </CoreTypography>
                    </SaveToSAcceptance>
                </ConditionalSkeleton>
            </DialogActions>
        </LeafDialog>
    )
}

export default memo(ToSAcceptanceModal)
