import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import useAcuteCare from '../AcuteCare/useAcuteCare'
import { useDailyCheckin } from '../../../context/pulse'
import {
    type AcuteCareBanner,
    type CompanyResourceGroup,
    type PulseSurveyQuestionDto
} from 'src/graphql/generated/autogenerated'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { FeatureFlags } from '../../../constants'
import { AssessmentSectionStatus } from '@thriveglobal/thrive-web-pulse-insights'
import { useAppSelector } from '../../../slices'
import { selectDciAcknowledgedDate } from '../../../slices/homePageSettings'
import { getCurrentDateWithoutTime } from '../../../utils/time/getCurrentDateWithoutTime'

export enum ResetType {
    SideBar = 'SideBar',
    RecommendedForYou = 'RecomendedForYou'
}

interface IGetState {
    assessmentStatusState: AssessmentSectionStatus
    dailyCheckinSurvey: PulseSurveyQuestionDto
    isLoadingDailyCheckin: boolean
    isDailyCheckinResponded: boolean
    showAcuteCareBanner: boolean
    banner: AcuteCareBanner
    showAcuteCareCard: boolean
    acuteCareLoading: boolean
    confirmedDciCompleted?: boolean
    isSavingPulseCheckin?: boolean
    mostRecentCompletedDate?: number
}

interface IGetStateReturn {
    isRecommendedContentVisible: boolean
    isAcuteCareBannerVisible: boolean
    isAcuteCareCardVisible: boolean
    showDciSurvey: boolean
    showDciCompletedCard: boolean
    resetType: ResetType
}

function getDeclutteredState({
    assessmentStatusState,
    dailyCheckinSurvey,
    isLoadingDailyCheckin,
    isDailyCheckinResponded,
    showAcuteCareBanner,
    banner,
    showAcuteCareCard,
    acuteCareLoading,
    confirmedDciCompleted,
    isSavingPulseCheckin,
    mostRecentCompletedDate
}: IGetState): IGetStateReturn {
    const noBaselineAssessment =
        assessmentStatusState !== null &&
        assessmentStatusState === AssessmentSectionStatus.Empty
    // Show the DCI survey if the user hasn't responded to it yet
    const showDciSurvey =
        noBaselineAssessment &&
        dailyCheckinSurvey &&
        !isSavingPulseCheckin &&
        !isLoadingDailyCheckin &&
        !isDailyCheckinResponded

    const alreadyDismissedCompletedToday =
        mostRecentCompletedDate === getCurrentDateWithoutTime().getTime()

    const showDciCompletedCard =
        (isSavingPulseCheckin || isDailyCheckinResponded) &&
        !alreadyDismissedCompletedToday &&
        !confirmedDciCompleted

    const isRecommendedContentVisible = true

    // Show the acute care banner if the recommended content is visible, and the BE recommends the user should see the acute care banner and the banner is loaded
    const isAcuteCareBannerVisible = showAcuteCareBanner && banner

    // Show the acute care card if the user should see the acute care card and the acute care resources have loaded
    const isAcuteCareCardVisible = showAcuteCareCard && !acuteCareLoading

    const resetType = ResetType.RecommendedForYou

    return {
        isRecommendedContentVisible,
        isAcuteCareBannerVisible: Boolean(isAcuteCareBannerVisible),
        isAcuteCareCardVisible,
        showDciSurvey: Boolean(showDciSurvey),
        resetType,
        showDciCompletedCard
    }
}

function getOldState({
    assessmentStatusState,
    dailyCheckinSurvey,
    isLoadingDailyCheckin,
    isDailyCheckinResponded,
    showAcuteCareBanner,
    banner,
    showAcuteCareCard,
    acuteCareLoading
}: IGetState): IGetStateReturn {
    const noBaselineAssessment =
        assessmentStatusState !== null &&
        assessmentStatusState === AssessmentSectionStatus.Empty
    // Show the DCI survey if the user hasn't responded to it yet
    const showDciSurvey =
        noBaselineAssessment &&
        dailyCheckinSurvey &&
        !isLoadingDailyCheckin &&
        !isDailyCheckinResponded

    // Show the recommended content if the user has responded to the DCI survey, waits for acute care to load and if the user shouldn't see the acute care card
    const isRecommendedContentVisible =
        noBaselineAssessment &&
        !showAcuteCareCard &&
        !showDciSurvey &&
        !acuteCareLoading &&
        !isLoadingDailyCheckin

    // Show the acute care banner if the recommended content is visible, and the BE recommends the user should see the acute care banner and the banner is loaded
    const isAcuteCareBannerVisible =
        isRecommendedContentVisible && showAcuteCareBanner && banner

    // Show the acute care card if the user should see the acute care card and the acute care resources have loaded
    const isAcuteCareCardVisible = showAcuteCareCard && !acuteCareLoading
    return {
        isRecommendedContentVisible,
        isAcuteCareBannerVisible: Boolean(isAcuteCareBannerVisible),
        isAcuteCareCardVisible,
        showDciSurvey: Boolean(showDciSurvey),
        resetType: ResetType.SideBar,
        showDciCompletedCard: false
    }
}

export interface IDciAndAcuteCareContext {
    isRecommendedContentVisible: boolean
    isAcuteCareBannerVisible: boolean
    isAcuteCareCardVisible: boolean
    showAcuteCareCard: boolean
    showAcuteCareBanner: boolean
    acuteCareLoading: boolean
    showDciSurvey: boolean
    acuteCareResources?: CompanyResourceGroup
    acuteCareBanner?: AcuteCareBanner
    dciSurvey?: PulseSurveyQuestionDto
    assessmentStatusState: AssessmentSectionStatus
    resetType?: ResetType
    showDciCompletedCard: boolean
    handleAcuteCareCardBackClick?: () => void
    handleAcuteCareBannerClick?: () => void
    handleAssessmentSectionStateChange: (value: AssessmentSectionStatus) => void
    handleDciCompletedCardClick?: () => void
}

const defaultContext: IDciAndAcuteCareContext = {
    isRecommendedContentVisible: false,
    isAcuteCareBannerVisible: false,
    isAcuteCareCardVisible: false,
    showAcuteCareCard: false,
    showAcuteCareBanner: false,
    acuteCareLoading: false,
    showDciSurvey: false,
    acuteCareResources: undefined,
    acuteCareBanner: undefined,
    dciSurvey: undefined,
    assessmentStatusState: undefined,
    resetType: ResetType.SideBar,
    showDciCompletedCard: false,
    handleAssessmentSectionStateChange: () => {}
}

export const DciAndAcuteCareContext =
    createContext<IDciAndAcuteCareContext>(defaultContext)

// Moved logic here to a common context to make it easier to untangle the acute care, DCI, and recommended content logic later
export const DciAndAcuteCareProvider = ({ children }) => {
    const {
        isDailyCheckinResponded,
        isLoadingDailyCheckin,
        dailyCheckinSurvey,
        recommendAcuteCare,
        isSavingPulseCheckin
    } = useDailyCheckin()
    const {
        showAcuteCareCard,
        showAcuteCareBanner,
        banner,
        acuteCareLoading,
        getResources,
        resources,
        handleAcuteCareCardBackClick,
        handleAcuteCareBannerClick
    } = useAcuteCare()

    const mostRecentCompletedDate = useAppSelector((state) =>
        selectDciAcknowledgedDate(state)
    )

    const [assessmentStatusState, handleAssessmentSectionStateChange] =
        useState<AssessmentSectionStatus | null>(null)

    const hasDeclutteredHomePage = useIsFeatureEnabled(
        FeatureFlags.HasDeclutteredHomePage,
        false,
        true
    )

    const [confirmedDciCompleted, setConfirmedDciCompleted] = useState(false)
    const baselineAssEnabled = useIsFeatureEnabled(
        FeatureFlags.HasBaselineAssessmentEnabled,
        false,
        true
    )

    useEffect(() => {
        if (!baselineAssEnabled) {
            handleAssessmentSectionStateChange(AssessmentSectionStatus.Empty)
        }
    }, [baselineAssEnabled, handleAssessmentSectionStateChange])

    const handleDciCompletedCardClick = useCallback(() => {
        setConfirmedDciCompleted(true)
    }, [setConfirmedDciCompleted])

    // Horrible hack to get resources to load, call should be a level up in an onCompleted callback
    useEffect(() => {
        if (recommendAcuteCare) {
            getResources(dailyCheckinSurvey.name, dailyCheckinSurvey.id)
        }
    }, [dailyCheckinSurvey, recommendAcuteCare, getResources])

    const value = useMemo<IDciAndAcuteCareContext>(() => {
        const commonFields = {
            showAcuteCareCard,
            showAcuteCareBanner,
            acuteCareLoading,
            acuteCareResources: resources as CompanyResourceGroup,
            acuteCareBanner: banner,
            dciSurvey: dailyCheckinSurvey,
            assessmentStatusState,
            handleAcuteCareCardBackClick,
            handleAcuteCareBannerClick,
            handleAssessmentSectionStateChange,
            handleDciCompletedCardClick
        }
        if (hasDeclutteredHomePage) {
            const state = getDeclutteredState({
                assessmentStatusState,
                dailyCheckinSurvey,
                isLoadingDailyCheckin,
                isDailyCheckinResponded,
                showAcuteCareBanner,
                banner,
                showAcuteCareCard,
                acuteCareLoading,
                confirmedDciCompleted,
                isSavingPulseCheckin,
                mostRecentCompletedDate
            })
            return {
                ...commonFields,
                ...state
            }
        } else {
            const state = getOldState({
                assessmentStatusState,
                dailyCheckinSurvey,
                isLoadingDailyCheckin,
                isDailyCheckinResponded,
                showAcuteCareBanner,
                banner,
                showAcuteCareCard,
                acuteCareLoading
            })
            return {
                ...commonFields,
                ...state
            }
        }
    }, [
        acuteCareLoading,
        banner,
        dailyCheckinSurvey,
        resources,
        showAcuteCareBanner,
        showAcuteCareCard,
        hasDeclutteredHomePage,
        handleAcuteCareBannerClick,
        handleAcuteCareCardBackClick,
        assessmentStatusState,
        handleAssessmentSectionStateChange,
        confirmedDciCompleted,
        handleDciCompletedCardClick,
        isLoadingDailyCheckin,
        isDailyCheckinResponded,
        isSavingPulseCheckin,
        mostRecentCompletedDate
    ])
    return (
        <DciAndAcuteCareContext.Provider value={value}>
            {children}
        </DciAndAcuteCareContext.Provider>
    )
}
