import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { ShareContentOnTeams } from '@thriveglobal/thrive-web-ms-teams-app'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { copyTextToClipboard } from '../../../utils/clipboard'
import { CopiedTooltipStyled } from './ShareResetPopupStyled'
import { autoCloseCopyTooltipTimeout } from './constants'

const messages = defineMessages({
    closeAriaLabel: {
        defaultMessage: 'Close',
        description: 'Close share reset popup button aria label'
    },
    copyAriaLabel: {
        defaultMessage: 'Copy to clipboard',
        description: 'Copy reset url to clipboard button aria label'
    },
    copied: {
        defaultMessage: 'Copied!',
        description: 'Copy reset url to clipboard success message'
    },
    shareResetOnTeam: {
        defaultMessage: 'Share {title} reset on microsoft teams',
        description: 'Share reset on microsoft teams message'
    }
})

export type ShareResetPopupProps = {
    open: boolean
    title: string
    url: string
    path: string
    content: JSX.Element
    onShared?: () => void
    onTeamsShared?: () => void
    onClose: () => void
}

function ShareResetPopup(props: ShareResetPopupProps): JSX.Element {
    const {
        open,
        title,
        url,
        path,
        content,
        onShared,
        onTeamsShared,
        onClose
    } = props
    const contentContainerRef = useRef<HTMLDivElement>(null)
    const theme = useTheme()
    const intl = useIntl()
    const copySuccessTooltipTimeout = useRef<NodeJS.Timeout | number>()
    const [isCopySuccessTooltipOpen, setIsCopySuccessTooltipOpen] =
        useState(false)

    const clearCopySuccessTimeout = useCallback(() => {
        if (copySuccessTooltipTimeout.current) {
            clearTimeout(copySuccessTooltipTimeout.current)
            copySuccessTooltipTimeout.current = null
        }
    }, [])

    useEffect(() => {
        return () => {
            clearCopySuccessTimeout()
        }
    }, [clearCopySuccessTimeout])

    const handleCopyUrl = useCallback(async () => {
        clearCopySuccessTimeout()

        await copyTextToClipboard(url, {
            container: contentContainerRef.current
        })
        setIsCopySuccessTooltipOpen(true)
        onShared?.()

        copySuccessTooltipTimeout.current = setTimeout(() => {
            setIsCopySuccessTooltipOpen(false)
            clearCopySuccessTimeout()
        }, autoCloseCopyTooltipTimeout)
    }, [url, clearCopySuccessTimeout, onShared])

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            aria-labelledby="share-reset-dialog-title"
            aria-describedby="share-reset-dialog-description"
            fullWidth
            maxWidth="sm"
            data-testid="ShareResetPopup"
            dialogTitle={title}
        >
            <DialogContent
                sx={{ px: theme.spacing(4) }}
                ref={contentContainerRef}
            >
                <DialogContentText id="share-reset-dialog-description">
                    <CoreTypography variant="body1" color="text.primary">
                        {content}
                    </CoreTypography>
                </DialogContentText>

                <TextField
                    sx={{
                        pt: theme.spacing(4),
                        pb: theme.spacing(0.5),
                        maxWidth: '400px'
                    }}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    value={url}
                />
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'block'
                }}
            >
                <ShareContentOnTeams
                    url={path}
                    renderMessageAsDeeplink={true}
                    //UnfurlLink will override deeplink if the FF is enabled
                    renderPathAndUnfurlLink={true}
                    ariaLabel={intl.formatMessage(messages.shareResetOnTeam, {
                        title
                    })}
                    onShared={onTeamsShared}
                />
                <CopiedTooltipStyled
                    open={isCopySuccessTooltipOpen}
                    title={intl.formatMessage(messages.copied)}
                    placement="right"
                    sx={{ fontSize: theme.typography.body1.fontSize }}
                    describeChild
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCopyUrl}
                        autoFocus
                        data-testid="CopyResetUrlButton"
                        aria-label={intl.formatMessage(messages.copyAriaLabel)}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Copy"
                                description="Copy reset url"
                            />
                        </CoreTypography>
                    </Button>
                </CopiedTooltipStyled>
            </DialogActions>
        </LeafDialog>
    )
}

export default React.memo(ShareResetPopup)
