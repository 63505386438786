import { memo } from 'react'
import withErrorComponentBoundary from '../../../hooks/withErrorComponentBoundary/withErrorComponentBoundary'
import SaveToSAcceptance from './SaveToSAcceptance'

export const SaveToSAcceptanceWithFallback = withErrorComponentBoundary(
    'SaveToSAcceptance',
    SaveToSAcceptance
)

export default memo(SaveToSAcceptanceWithFallback)
