import { Stack } from '@mui/material'
import React, { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface ScrollToTopProps {
    children: ReactNode
}

export const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
    const location = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return (
        <Stack minHeight="inherit" data-testid="scroll-to-top">
            {children}
        </Stack>
    )
}

export default ScrollToTop
