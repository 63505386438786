import { useCallback } from 'react'
import { AssessmentAttemptSuccess } from '../graphql/generated/autogenerated'
import { Avo, AvoTypes } from '@thriveglobal/thrive-web-tracking'

/**
 * @typedef {Object} UseTrackAssessmentSurveySubmitProps
 * @property {AvoTypes.ActivityTypeValueType} activityType - The type of activity (e.g., onboarding, engagement).
 * @property {AssessmentAttemptSuccess} assessmentAttempt - The details of the assessment attempt.
 */
type UseTrackAssessmentSurveySubmitProps = {
    activityType: AvoTypes.ActivityTypeValueType
    assessmentAttempt: AssessmentAttemptSuccess
}

/**
 * @typedef {Object} UseTrackAssessmentSurveySubmitProps
 * @property {AvoTypes.ActivityTypeValueType} activityType - The type of activity (e.g., onboarding, engagement).
 * @property {AssessmentAttemptSuccess} assessmentAttempt - The details of the assessment attempt.
 */

/**
 * A hook to track the completion of assessment surveys.
 *
 * @returns {Function} A callback function that tracks the completion of an assessment survey.
 */
const useTrackAssessmentSurveyCompleted = () => {
    return useCallback(
        ({
            activityType,
            assessmentAttempt
        }: UseTrackAssessmentSurveySubmitProps) => {
            const {
                assessmentId,
                assessmentAttemptId,
                name: assessmentName,
                variant: assessmentVariant
            } = assessmentAttempt

            Avo.surveyCompleted({
                featureType: 'assessment',
                activityType,
                isOnboarding: true,
                assessmentId,
                assessmentAttemptId,
                assessmentName,
                assessmentVariant,
                isSystemEvent: false
            })
        },
        []
    )
}

export default useTrackAssessmentSurveyCompleted
