import {
    type CombinedState,
    createSelector,
    createSlice
} from '@reduxjs/toolkit'

export const settingsSliceName = 'homepageSettings'

export type HomeSettingsState = {
    dciCompletedAcknowledged: {
        date?: number
    }
}

export const initialState: HomeSettingsState = {
    dciCompletedAcknowledged: {}
}

export type RootStateWithHomeSettingsState = CombinedState<{
    [settingsSliceName]: HomeSettingsState
}>

export const homeSettingsSlice = createSlice({
    name: settingsSliceName,
    initialState,
    reducers: {
        clearDciCompletedAcknowledged: (state) => {
            state.dciCompletedAcknowledged = {}
        },
        setDciCompletedAcknowledged: (
            state,
            { payload }: { payload: { date: number } }
        ) => {
            state.dciCompletedAcknowledged = payload
        }
    }
})

export const { clearDciCompletedAcknowledged, setDciCompletedAcknowledged } =
    homeSettingsSlice.actions

export type HomeSettingsSliceActions =
    (typeof homeSettingsSlice.actions)[keyof typeof homeSettingsSlice.actions]

const selectDciAcknowledgedState = (state: RootStateWithHomeSettingsState) =>
    state[settingsSliceName]

export const selectDciAcknowledgedDate = createSelector(
    selectDciAcknowledgedState,
    (state) => state.dciCompletedAcknowledged.date
)

export default homeSettingsSlice.reducer
