import {
    CoreTypography,
    LeafConfirmationDialog
} from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'

/**
 * Props for the AssessmentConfirmationDialog component.
 * @typedef {Object} AssessmentConfirmationDialogProps
 * @property {boolean} isOpen - Controls whether the confirmation dialog is open.
 * @property {() => void} onClose - Function to close the confirmation dialog.
 * @property {() => void} onConfirm - Function to confirm the action.
 */
interface AssessmentConfirmationDialogProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

const messages = defineMessages({
    backButtonLabel: {
        defaultMessage: 'Back',
        description: 'Button label for going back to the previous page.'
    },
    nextButtonLabel: {
        defaultMessage: 'Next',
        description: 'Button label for proceeding to the next page.'
    },
    finishButtonLabel: {
        defaultMessage: 'Finish',
        description: 'Button label for the final question'
    },
    confirmationDialogTitle: {
        defaultMessage: 'Are you sure?',
        description:
            "Check if user is sure they'd like to continue with submitting their survey responses."
    },
    confirmationDialogContent: {
        defaultMessage:
            'Once you submit your responses you won’t be able to change them.',
        description:
            "Notice to users that they won't be able to change their answers once they continue."
    },
    confirmationDialogConfirmation: {
        defaultMessage: 'Confirm',
        description: 'Confirmation to continue'
    }
})

/**
 * AssessmentConfirmationDialog displays a confirmation dialog asking users
 * if they are sure they want to submit their responses.
 *
 * @param {AssessmentConfirmationDialogProps} props - The props for the dialog component.
 * @returns {JSX.Element} A confirmation dialog with customizable actions.
 */
const AssessmentConfirmationDialog: React.FC<
    AssessmentConfirmationDialogProps
> = ({ isOpen, onConfirm, onClose }) => {
    const { formatMessage } = useIntl()

    return (
        <LeafConfirmationDialog
            dialogTitle={
                <CoreTypography variant={'h3'}>
                    {formatMessage(messages.confirmationDialogTitle)}
                </CoreTypography>
            }
            dialogContent={
                <CoreTypography variant={'body1'}>
                    {formatMessage(messages.confirmationDialogContent)}
                </CoreTypography>
            }
            primaryButtonText={formatMessage(
                messages.confirmationDialogConfirmation
            )}
            primaryButtonOnClick={onConfirm}
            secondaryButtonText={formatMessage(messages.backButtonLabel)}
            secondaryButtonOnClick={onClose}
            onClose={onClose}
            open={isOpen}
        />
    )
}

export default AssessmentConfirmationDialog
