import React from 'react'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { AlertProps, AlertTitle } from '@mui/material'

/**
 * Props for the AssessmentAlert component.
 *
 * @typedef {Object} AssessmentAlertProps
 * @property {boolean} isOpen - handle opening of alert
 * @property {string} title - The title of the alert.
 * @property {string} description - The description text displayed in the alert.
 * @property {() => void} onClose - A callback function triggered when the alert is closed.
 */
interface AssessmentAlertProps extends AlertProps {
    isOpen: boolean
    title: string
    description: string
    onClose: () => void
}

/**
 * AssessmentAlert Component
 *
 * A warning alert component with a title and description, customizable for assessments.
 * Uses CoreAlert and CoreTypography components for styling and structure.
 *
 * @param {AssessmentAlertProps} props - The props for the AssessmentAlert component.
 */
const AssessmentAlert: React.FC<AssessmentAlertProps> = ({
    isOpen,
    title,
    description,
    onClose,
    severity
}) => {
    return (
        <CoreAlert
            onClose={onClose}
            severity={severity}
            variant="standard"
            data-testid="alert-null-answer"
            sx={{
                visibility: isOpen ? 'visible' : 'hidden'
            }}
        >
            <AlertTitle>
                <CoreTypography component="h5" variant="body1">
                    {title}
                </CoreTypography>
            </AlertTitle>
            <CoreTypography component="h5" variant="body1">
                {description}
            </CoreTypography>
        </CoreAlert>
    )
}

export default AssessmentAlert
