import React, { memo, ReactNode, FC } from 'react'
import { Stack, Card, CardContent, SxProps, Box } from '@mui/material'
import AssessmentActions from '../AssessmentActions'
import PrivacyNotice from '../../../../components/PrivacyNotice'

/**
 * Enum representing the wrapper variants for the AssessmentWrapper component.
 */
export enum AssessmentWrapperVariant {
    DIALOG = 'dialog',
    CARD = 'card',
    NOWRAPPER = 'nowrapper'
}

/**
 * Props for the AssessmentWrapper component.
 * @property {WrapperVariant} wrapperVariant - The variant of the wrapper to use.
 * @property {SxProps} [sx] - Optional styling applied to the Card component.
 * @property {React.ComponentType} ProgressIndicator - The component to render as the progress indicator.
 * @property {ReactNode} children - The child elements to render within the wrapper.
 */
interface AssessmentWrapperProps {
    wrapperVariant: 'dialog' | 'card' | 'nowrapper'
    sx?: SxProps
    ProgressIndicator: React.ComponentType
    Alert?: React.ComponentType
    children: ReactNode
    handleBackClicked?: () => void
    handleNextClicked?: () => void
    isFinalStep: boolean
    isFirstStep: boolean
}

/**
 * A component that wraps its children based on the specified wrapper variant. Works only for the assessment component
 *
 * @param {AssessmentWrapperProps} props - The props for the component.
 * @returns {JSX.Element} The wrapped content based on the wrapper variant.
 */
const AssessmentWrapperComponent: FC<AssessmentWrapperProps> = ({
    wrapperVariant,
    sx,
    ProgressIndicator,
    Alert,
    children,
    handleBackClicked,
    handleNextClicked,
    isFirstStep,
    isFinalStep
}) => {
    switch (wrapperVariant) {
        case AssessmentWrapperVariant.DIALOG:
            return (
                <Stack px={'2px'} height={'100%'} gap={2}>
                    <Box>
                        <ProgressIndicator />
                        <Card sx={sx}>
                            <CardContent>{children}</CardContent>
                        </Card>
                        <AssessmentActions
                            isFinalStep={isFinalStep}
                            isFirstStep={isFirstStep}
                            handleBackClicked={handleBackClicked}
                            handleNextClicked={handleNextClicked}
                        />
                        <Alert />
                    </Box>
                    <PrivacyNotice />
                </Stack>
            )
        case AssessmentWrapperVariant.CARD:
            return (
                <Card sx={sx}>
                    <CardContent>
                        <ProgressIndicator />
                        {children}
                        <AssessmentActions
                            isFinalStep={isFinalStep}
                            isFirstStep={isFirstStep}
                            handleBackClicked={handleBackClicked}
                            handleNextClicked={handleNextClicked}
                        />
                        <PrivacyNotice />
                    </CardContent>
                </Card>
            )
        case AssessmentWrapperVariant.NOWRAPPER:
        default:
            return (
                <>
                    <ProgressIndicator />
                    {children}
                    <AssessmentActions
                        isFinalStep={isFinalStep}
                        isFirstStep={isFirstStep}
                        handleBackClicked={handleBackClicked}
                        handleNextClicked={handleNextClicked}
                    />
                </>
            )
    }
}

/**
 * Memoized version of the AssessmentWrapper component to optimize rendering.
 */
const AssessmentWrapper = memo(AssessmentWrapperComponent)

export default AssessmentWrapper
