import { ComponentType, useMemo } from 'react'
import { useWearablesAccess } from '../../hooks'

/**
 * A higher-order component (HOC) that verifies wearables access before rendering the wrapped component.
 *
 * @template P - Props type of the wrapped component.
 * @param {ComponentType<P>} WrappedComponent - The component to wrap.
 * @returns {ComponentType<P>} - A new component that checks wearables access and conditionally renders the wrapped component.
 */
const withWearablesAccessVerification = <P extends object>(
    WrappedComponent: ComponentType<P>
): ComponentType<P> => {
    return (props) => {
        const { loading, isActivityDataEnabled, isSleepDataEnabled, error } =
            useWearablesAccess()
        const hasAccess = useMemo(
            () => isActivityDataEnabled || isSleepDataEnabled,
            [isActivityDataEnabled, isSleepDataEnabled]
        )

        if (!hasAccess || loading || error) {
            return null
        }

        return <WrappedComponent {...props} />
    }
}

export default withWearablesAccessVerification
