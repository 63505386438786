import { Apollo } from '@thriveglobal/thrive-web-core'
import { createContext } from 'react'
import {
    PulseSurveyQuestionDto,
    SavePulseCheckInMutation
} from '../../graphql/generated/autogenerated'

const defaultContextValue: PulseContextType = {
    dailyCheckinSurvey: null,
    recommendAcuteCare: false,
    loading: false,
    error: false,
    responded: false,
    fetch: () => Promise.resolve(true),
    submit: () => null,
    dailyCheckinSubmitted: false
}

export type PulseContextType = {
    dailyCheckinSurvey: PulseSurveyQuestionDto | null
    recommendAcuteCare: boolean
    loading: boolean
    error: boolean
    responded: boolean
    isSavingPulseCheckin?: boolean
    fetch: () => Promise<boolean>
    submit: ({
        option
    }) => Promise<
        Apollo.FetchResult<
            SavePulseCheckInMutation,
            Record<string, any>,
            Record<string, any>
        >
    >
    dailyCheckinSubmitted: boolean
}

export default createContext(defaultContextValue)
