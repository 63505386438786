import { useMemo } from 'react'
import { AssessmentAttemptSuccess } from '../../../../graphql/generated/autogenerated'

/**
 * Custom hook to validate an assessment based on the presence of responses and validation rules.
 *
 * @param {AssessmentAttemptSuccess} assessmentAttempt - The current assessment attempt containing given responses.
 * @param {AssessmentAttemptSuccess} [latestResponse] - The latest response in the assessment attempt (optional).
 * @returns {Object} - An object containing the `isValid` function to validate the assessment.
 */
const useAssessmentValidation = (
    assessmentAttempt: AssessmentAttemptSuccess,
    latestResponse?: AssessmentAttemptSuccess
) => {
    /**
     * Memoized value indicating whether there are any responses in the assessment attempts.
     *
     * @type {boolean}
     */
    const hasResponses = useMemo(() => {
        /**
         * Checks if there are any non-null responses in the given response list.
         *
         * @param {Array} responses - The list of responses to check.
         * @returns {boolean} - Returns true if at least one response is non-null.
         */
        const checkHasResponses = (responses) =>
            responses?.some((response) => response?.response !== null)

        return (
            checkHasResponses(latestResponse?.givenResponses ?? []) ||
            checkHasResponses(assessmentAttempt?.givenResponses ?? [])
        )
    }, [latestResponse?.givenResponses, assessmentAttempt?.givenResponses])

    /**
     * Validates the assessment based on the current step and validation rules.
     *
     * @param {boolean} finalStep - Whether the current step is the final step of the assessment.
     * @param {boolean} skipValidation - Whether to skip the validation process.
     * @returns {boolean} - Returns true if the assessment is valid.
     */
    const isValid = (finalStep: boolean, skipValidation: boolean) => {
        if (skipValidation) return true
        return finalStep ? hasResponses : true
    }

    return { isValid }
}

export default useAssessmentValidation
