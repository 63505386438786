import { ApolloError } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
    useGetWearablesAccessQuery,
    useGetUserCountryQuery
} from '../../graphql/generated/autogenerated'
import { isFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { FeatureFlags } from '../../enums'

type UseWearablesAccessReturn =
    | {
          loading: true
          error: undefined
          isActivityDataEnabled: undefined
          isSleepDataEnabled: undefined
      }
    | {
          loading: false
          error: ApolloError | undefined
          isActivityDataEnabled: undefined
          isSleepDataEnabled: undefined
      }
    | {
          loading: false
          error: undefined
          isActivityDataEnabled: boolean
          isSleepDataEnabled: boolean
      }

export const useWearablesAccess = (): UseWearablesAccessReturn => {
    const {
        data: wearablesAccessData,
        loading: wearablesLoading,
        error: wearablesAccessError
    } = useGetWearablesAccessQuery()
    const {
        data: identityUserData,
        loading: identityUserLoading,
        error: identityUserError
    } = useGetUserCountryQuery()

    const [hasWearablesAccessByCountry, setHasWearablesAccessByCountry] =
        useState<boolean | null>(null)
    const [featureLoading, setFeatureLoading] = useState(true)

    useEffect(() => {
        const countryCode =
            identityUserData?.identity.userCountry.getCountryCodeHeader

        if (countryCode) {
            const fetchFeatureAccess = async () => {
                setFeatureLoading(true)
                try {
                    const result = await isFeatureEnabled(
                        FeatureFlags.HasWearablesAccess,
                        false,
                        true,
                        { countryCode }
                    )
                    setHasWearablesAccessByCountry(result as boolean)
                } catch (error) {
                    setHasWearablesAccessByCountry(false)
                } finally {
                    setFeatureLoading(false)
                }
            }
            fetchFeatureAccess()
        } else {
            setFeatureLoading(false)
        }
    }, [identityUserData])

    if (wearablesLoading || identityUserLoading || featureLoading) {
        return {
            loading: true,
            error: undefined,
            isActivityDataEnabled: undefined,
            isSleepDataEnabled: undefined
        }
    }

    if (wearablesAccessError || identityUserError) {
        return {
            loading: false,
            error: wearablesAccessError || identityUserError,
            isActivityDataEnabled: undefined,
            isSleepDataEnabled: undefined
        }
    }

    if (hasWearablesAccessByCountry === false) {
        return {
            loading: false,
            error: undefined,
            isActivityDataEnabled: false,
            isSleepDataEnabled: false
        }
    }

    return {
        loading: false,
        error: undefined,
        isActivityDataEnabled:
            wearablesAccessData?.wearables.wearablesAccess
                .isActivityDataEnabled || false,
        isSleepDataEnabled:
            wearablesAccessData?.wearables.wearablesAccess.isSleepDataEnabled ||
            false
    }
}
