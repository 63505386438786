import { memo } from 'react'
import withErrorComponentBoundary from '../../../hooks/withErrorComponentBoundary/withErrorComponentBoundary'
import withTeamsComponentsEnabled from '../../../hooks/withTeamsComponentsEnabled/withTeamsComponentsEnabled'
import ScheduleContentButton from './ScheduleContentButton'

export const ScheduleContentButtonWithFallback = withErrorComponentBoundary(
    'ScheduleContentButton',
    withTeamsComponentsEnabled(ScheduleContentButton)
)

export default memo(ScheduleContentButtonWithFallback)
