import { memo } from 'react'
import withErrorComponentBoundary from '../../../hooks/withErrorComponentBoundary/withErrorComponentBoundary'
import ToSAcceptance from './ToSAcceptance'

export const ToSAcceptanceWithFallback = withErrorComponentBoundary(
    'ToSAcceptance',
    ToSAcceptance
)

export default memo(ToSAcceptanceWithFallback)
