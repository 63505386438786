import { ApolloError } from '@apollo/client'
import { DialogContent } from '@mui/material'
import {
    LeafDialog,
    LeafDialogProps,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useState } from 'react'
import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentItem
} from '../../../graphql/generated/autogenerated'
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen'
import AbandonAssessmentDialog from '../AbandonAssessmentDialog/index'
import { BaselineAssessmentResponse } from '../BaselineAssessment'
import { Assessment } from '../index'
import BaselineAssessmentLoader from '../BaselineAssessmentLoader'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { AssessmentWrapperVariant } from '../Assessment/AssessmentWrapper'

export interface BaselineAssessmentDialogProps extends LeafDialogProps {
    assessmentAttempt: AssessmentAttemptSuccess
    error: ApolloError
    assessmentAttemptError: AssessmentAttemptError
    loading: boolean
    assessmentItems: AssessmentItem[]
    openedByShareLink?: boolean
    onAssessmentCompleted?: () => void
}
const BaselineAssessmentDialog: React.FC<BaselineAssessmentDialogProps> = ({
    assessmentAttempt,
    error,
    assessmentAttemptError,
    assessmentItems,
    loading,
    onAssessmentCompleted,
    openedByShareLink,
    ...dialogProps
}) => {
    const theme = useTheme()
    const isSmallScreen = useIsSmallScreen()
    const [isAbandonDialogOpen, setIsAbandonDialogOpen] = useState<boolean>()
    const [showLoader, setShowLoader] = useState<boolean>(false)

    const onAssessmentComplete = (response: BaselineAssessmentResponse) => {
        dialogProps.onClose()
        setShowLoader(false)
        if (response.completed) {
            onAssessmentCompleted?.()
            //TODO: show success alert when completed or error alert when closed in error
        }
    }

    const onStartAssessment = useCallback(() => {
        const {
            assessmentId,
            assessmentAttemptId,
            name: assessmentName,
            variant: assessmentVariant
        } = assessmentAttempt

        Avo.surveyStarted({
            featureType: 'assessment',
            activityType: 'shared_baseline_assessment_started',
            isOnboarding: true,
            assessmentId,
            assessmentAttemptId,
            assessmentName,
            assessmentVariant,
            isSystemEvent: false,
            onboardingAttemptNumber: null,
            responseText: null
        })
    }, [assessmentAttempt])

    const closeBaselineAssessment = () => {
        setIsAbandonDialogOpen(true)
    }
    const onCloseAbandonDialog = () => {
        setIsAbandonDialogOpen(false)
    }
    const onContinueAssessment = () => {
        setIsAbandonDialogOpen(false)
        dialogProps.onClose()
    }

    useEffect(() => {
        if (openedByShareLink) {
            onStartAssessment()
        }
    }, [onStartAssessment, openedByShareLink])

    return (
        <LeafDialog
            {...dialogProps}
            fullScreen
            PaperProps={{
                sx: {
                    background: theme.gradient.light,
                    padding: 0
                }
            }}
            onClose={closeBaselineAssessment}
        >
            <DialogContent
                data-testid={'baseline-assessment-dialog-content'}
                sx={{
                    mt: isSmallScreen ? 4 : 8,
                    maxWidth: theme.breakpoints.values.md,
                    width: '100%',
                    alignSelf: 'center'
                }}
            >
                {showLoader && <BaselineAssessmentLoader />}
                <AbandonAssessmentDialog
                    assessmentAttempt={assessmentAttempt}
                    isOpen={isAbandonDialogOpen}
                    handleOnClose={onCloseAbandonDialog}
                    handleOnContinue={onCloseAbandonDialog}
                    handleOnCancel={onContinueAssessment}
                />
                <Assessment
                    assessmentAttempt={assessmentAttempt}
                    error={error}
                    assessmentAttemptError={assessmentAttemptError}
                    loading={loading}
                    assessmentItems={assessmentItems}
                    onCompleteAssessment={onAssessmentComplete}
                    responseDirection={'column'}
                    skipConfirmationDialog
                    retainProgress
                    wrapperVariant={AssessmentWrapperVariant.DIALOG}
                    sx={{ textAlignLast: 'center' }}
                    setShowLoader={setShowLoader}
                    openedByShareLink={openedByShareLink}
                    skipValidation={false}
                />
            </DialogContent>
        </LeafDialog>
    )
}

export default BaselineAssessmentDialog
