import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    SxProps
} from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useEffect, useMemo, useState } from 'react'
import {
    AssessmentAttemptSuccess,
    AssessmentItem,
    ResponseInput,
    ResponseOptionCms
} from '../../../../graphql/generated/autogenerated'

export interface AssessmentStepStyleProps {
    responseDirection?: 'row' | 'column'
    hideNavButtons?: boolean
    responseStyle?: 'radio' | 'button'
    autoProgress?: boolean
    skipConfirmationDialog?: boolean
}
export interface AssessmentStepProps extends AssessmentStepStyleProps {
    step: number
    assessmentItem: AssessmentItem
    assessmentAttempt: AssessmentAttemptSuccess
    retainProgress?: boolean
    sx?: SxProps
    handleResponseInputChange: (responseInput: ResponseInput) => void
    setSelectedInputResponse: (responseInput: ResponseInput) => void
}

function responseOptionOrderFn(a: ResponseOptionCms, b: ResponseOptionCms) {
    if (a.displayOrder < b.displayOrder) {
        return -1
    } else if (a.displayOrder > b.displayOrder) {
        return 1
    }
    return 0
}

const AssessmentStep: React.FC<AssessmentStepProps> = ({
    step,
    assessmentItem,
    assessmentAttempt,
    hideNavButtons = false,
    autoProgress = true,
    responseDirection = 'row',
    responseStyle = 'column',
    retainProgress = false,
    sx = {},
    handleResponseInputChange,
    setSelectedInputResponse
}) => {
    const theme = useTheme()
    const responseOptions = useMemo(
        () => assessmentItem.responses.sort(responseOptionOrderFn),
        [assessmentItem.responses]
    )

    const [selectedResponse, setSelectedResponse] =
        useState<ResponseInput>(undefined)

    useEffect(() => {
        if (
            retainProgress &&
            assessmentAttempt?.givenResponses &&
            assessmentAttempt?.givenResponses[step - 1]
        ) {
            const retainedResponse =
                assessmentAttempt?.givenResponses[step - 1]?.response
            if (retainedResponse?.value) {
                const response = {
                    normalizedValue: retainedResponse.normalizedValue,
                    value: retainedResponse.value,
                    text: retainedResponse.text
                } as ResponseInput

                setSelectedResponse(response)
                setSelectedInputResponse(response)
            }
        }
    }, [
        retainProgress,
        assessmentAttempt?.givenResponses,
        step,
        setSelectedInputResponse
    ])

    const handleOptionClicked = (e: any, option: ResponseOptionCms) => {
        const responseInput = {
            text: option.text,
            value: option.value,
            normalizedValue: option.normalizedValue
        }
        setSelectedResponse(responseInput)
        setSelectedInputResponse(responseInput)
        if (
            (autoProgress || hideNavButtons) &&
            e.pageX !== 0 &&
            e.pageY !== 0
        ) {
            setTimeout(() => handleResponseInputChange(responseInput), 50)
        }
    }

    const responseStyleProps = useMemo(() => {
        switch (responseStyle) {
            case 'radio':
                return {
                    justifyContent: 'left',
                    textAlign: 'left'
                }
            case 'button':
                return {
                    justifyContent: 'center',
                    textAlign: 'center'
                }
            default:
                return {}
        }
    }, [responseStyle])

    return (
        <Stack
            display={'flex'}
            gap={1}
            sx={sx}
            data-testid={'assessment-step-' + assessmentItem.id}
        >
            <FormControl fullWidth>
                <FormLabel
                    id={'assessment-item-' + assessmentItem.id + '-label'}
                >
                    <CoreTypography
                        variant={'h3'}
                        color={'text.primary'}
                        textAlign={'start'}
                        mb={2}
                    >
                        {assessmentItem.questionText}
                    </CoreTypography>
                </FormLabel>
                <RadioGroup
                    aria-labelledby={
                        'assessment-item-' + assessmentItem.id + '-label'
                    }
                    name={'assessment-form-' + assessmentItem.id}
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexFlow: responseDirection,
                        gap: '8px'
                    }}
                >
                    {responseOptions.map((option, i) => (
                        <FormControlLabel
                            key={assessmentItem.id + ' response ' + i}
                            id={assessmentItem.id + ' response ' + i}
                            value={option.value}
                            control={
                                <Radio
                                    onClick={(e) =>
                                        handleOptionClicked(e, option)
                                    }
                                    sx={{
                                        color: 'inherit !important',
                                        ...(responseStyle === 'button' && {
                                            padding: 0,
                                            '.MuiSvgIcon-root': {
                                                display: 'none'
                                            }
                                        })
                                    }}
                                />
                            }
                            label={
                                <CoreTypography
                                    customVariant={'buttonNormal'}
                                    width={'fit-content'}
                                >
                                    {option.text}
                                </CoreTypography>
                            }
                            checked={option.value === selectedResponse?.value}
                            aria-checked={
                                option.value === selectedResponse?.value
                            }
                            sx={{
                                borderRadius: `${theme.shape.borderRadius}px`,
                                width: '100%',
                                padding: 1,
                                minHeight: '44px',
                                m: 0,
                                ...(option.value === selectedResponse?.value
                                    ? {
                                          // colors based on selected state
                                          backgroundColor: 'secondary.main',
                                          color: 'secondary.contrastText'
                                      }
                                    : {
                                          backgroundColor:
                                              theme.palette.grey[100],
                                          color: 'text.primary'
                                      }),
                                ...{ responseStyleProps }
                            }}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Stack>
    )
}

export default AssessmentStep
