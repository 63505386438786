import { FormattedMessage } from 'react-intl'
import { memo } from 'react'

export interface IntentionHeadingProps {
    intention?: string
    focusAreas?: string[]
}

function IntentionHeading({ intention, focusAreas }: IntentionHeadingProps) {
    if (!intention) {
        if (!focusAreas || focusAreas.length === 0) {
            return (
                <FormattedMessage
                    defaultMessage="Today's Microsteps"
                    description="Label for the Today's Microsteps section"
                />
            )
        }

        switch (true) {
            case focusAreas.length === 1:
                return (
                    <FormattedMessage
                        defaultMessage="I want to build better habits around: {focusArea}"
                        description="Label when user wants to focus on one focus area"
                        values={{ focusArea: focusAreas[0] }}
                    />
                )
            case focusAreas.length === 2:
                return (
                    <FormattedMessage
                        defaultMessage="I want to build better habits around: {focusArea1} and {focusArea2}"
                        description="Label when user wants to focus on two focus areas"
                        values={{
                            focusArea1: focusAreas[0],
                            focusArea2: focusAreas[1]
                        }}
                    />
                )
            case focusAreas.length >= 3:
                return (
                    <FormattedMessage
                        defaultMessage="I want to build better habits around: {focusArea1}, {focusArea2}, and {focusArea3}"
                        description="Label when user wants to focus on three focus areas"
                        values={{
                            focusArea1: focusAreas[0],
                            focusArea2: focusAreas[1],
                            focusArea3: focusAreas[2]
                        }}
                    />
                )
        }
    }
    return <>{intention}</>
}

export default memo(IntentionHeading)
