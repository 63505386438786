import { FrameContexts, chat, people, sharing } from '@microsoft/teams-js'
import { Button } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { ReactElement, memo, useCallback, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import useTeamsContext from '../../../../shared/hooks/useTeamsContext/useTeamsContext'
import useTeamsUrl from '../../../../shared/hooks/useTeamsUrl/useTeamsUrl'

export interface ShareContentOnTeamsProps extends JSX.IntrinsicAttributes {
    button?: ReactElement<any, any>
    url?: string
    urlText?: string
    message?: string
    ariaLabel?: string
    disableTracking?: boolean
    onShared?: (shareSuccessful: boolean) => void
    renderMessageAsDeeplink?: boolean
    renderPathAndUnfurlLink?: boolean
    chatSharing?: ChatSharingProps
    image?: ShareImageProps
}

export interface ShareImageProps {
    src: string
    alt: string
}

export interface ChatSharingProps {
    title: string
    enabled: boolean
}

const messages = defineMessages({
    shareContent: {
        defaultMessage: 'Share through microsoft teams',
        description: 'Share through teams'
    }
})

const ShareContentOnTeams: React.FC<ShareContentOnTeamsProps> = ({
    button,
    url,
    urlText,
    message,
    ariaLabel,
    disableTracking,
    onShared,
    renderMessageAsDeeplink,
    renderPathAndUnfurlLink,
    chatSharing,
    image
}) => {
    const { formatMessage } = useIntl()
    const { loading, context, isMsTeams } = useTeamsContext()
    const { renderedUrl, isUrlValid } = useTeamsUrl(
        url,
        renderMessageAsDeeplink,
        renderPathAndUnfurlLink,
        'people_picker_share',
        'msteams',
        disableTracking
    )

    const { chatSupported, sharingSupported } = useMemo(
        () => ({
            chatSupported:
                isMsTeams && chat.isSupported() && people.isSupported(),
            sharingSupported: isMsTeams && sharing.isSupported()
        }),
        [isMsTeams]
    )

    const shouldUseChatSharing = useMemo(() => {
        // Sharing module is not supported in some contexts
        const unSupportedContext =
            context?.page?.frameContext === FrameContexts.task ||
            context?.page?.frameContext === FrameContexts.stage

        return (
            (chatSharing?.enabled || unSupportedContext || image || urlText) &&
            chatSupported
        )
    }, [context, chatSupported, chatSharing, image, urlText])

    const shouldUseMessage = useMemo(
        () => message || image || urlText,
        [message, image, urlText]
    )

    const messageToShare = useMemo(() => {
        if (!shouldUseMessage) {
            return null
        }

        let renderedMessage = '<div>'

        if (image) {
            renderedMessage += `<img src="${image.src}" alt="${image.alt}">`
        }

        if (message) {
            renderedMessage += message
        }

        if (renderedUrl && (isUrlValid || renderMessageAsDeeplink)) {
            renderedMessage += `<a href="${renderedUrl}">${
                urlText ?? renderedUrl
            }</a>`
        }

        return renderedMessage + '</div>'
    }, [
        image,
        message,
        renderedUrl,
        urlText,
        isUrlValid,
        renderMessageAsDeeplink,
        shouldUseMessage
    ])

    const openGroupChat = useCallback(
        (users: people.PeoplePickerResult[]) => {
            chat.openGroupChat({
                users: users.map((person) => person.email),
                message: messageToShare
            })
                .then(() => onShared?.(true))
                .catch(() => onShared?.(false))
        },
        [messageToShare, onShared]
    )

    const onShare = useCallback(() => {
        if (shouldUseChatSharing) {
            people
                .selectPeople({ title: chatSharing?.title })
                .then(openGroupChat)
                .catch(() => onShared?.(false))
        } else if (sharingSupported) {
            sharing
                ?.shareWebContent({
                    content: [
                        {
                            type: 'URL',
                            url: urlText ? null : renderedUrl,
                            message: messageToShare,
                            preview: true
                        }
                    ]
                })
                .then(() => onShared?.(true))
                .catch(() => onShared?.(false))
        }
    }, [
        shouldUseChatSharing,
        chatSharing,
        onShared,
        openGroupChat,
        sharingSupported,
        renderedUrl,
        messageToShare,
        urlText
    ])

    const shareButton = useMemo(
        () =>
            button ? (
                React.cloneElement(button, {
                    onClick: onShare
                })
            ) : (
                <Button
                    variant="contained"
                    onClick={onShare}
                    endIcon={<LeafIcon icon="microsoft" iconStyle="brands" />}
                    aria-label={
                        ariaLabel
                            ? ariaLabel
                            : formatMessage(messages.shareContent)
                    }
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Share to Teams"
                            description="share on teams button"
                        />
                    </CoreTypography>
                </Button>
            ),
        [button, ariaLabel, onShare, formatMessage]
    )

    if (
        (!image && !message && !url) ||
        loading ||
        !isMsTeams ||
        (!chatSupported && !sharingSupported)
    ) {
        return null
    }

    return shareButton
}

export default memo(ShareContentOnTeams)
