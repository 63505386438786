import { getRedirectUri } from '@thriveglobal/thrive-web-auth-core'

export function getRelayOrRedirectUrl() {
    const relayUrl = new URLSearchParams(window?.location?.search).get('relay')
    const redirectUrl = getRedirectUri()
    return relayUrl || redirectUrl
}

export function getPostLoginRedirectPath() {
    const redirectUrl = getRelayOrRedirectUrl()
    // Url can be full or relative url but we only want to return relative part
    if (redirectUrl && redirectUrl !== '/') {
        const base = window.location.origin
        const url = new URL(redirectUrl, base)
        const path = url.pathname
        const search = url.search
        const hash = url.hash
        return `${path}${search}${hash}`
    }
    return '/'
}
