import React from 'react'
import { Stack, Box, LinearProgress } from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'

/**
 * Props for the ProgressIndicator component.
 * @property {string} progressIndicatorId - The ID used for accessibility of the progress bar.
 * @property {number} step - The current step in the progress.
 * @property {number} totalSteps - The total number of steps.
 * @property {number} progressBarValue - The progress bar value (0-100).
 * @property {object} messages - The intl messages for localization.
 */
interface AssessmentProgressIndicatorProps {
    progressIndicatorId: string
    step: number
    totalSteps: number
    progressBarValue: number
}

const messages = defineMessages({
    currentStepHeader: {
        defaultMessage: '{currentStep} of {totalSteps}',
        description:
            'Describes current step of total steps in process. Ex: 1 of 5'
    }
})

/**
 * Displays a progress indicator with a step header and a progress bar.
 *
 * @param {AssessmentProgressIndicatorProps} props - The props for the component.
 * @returns {JSX.Element} The progress indicator component.
 */
const AssessmentProgressIndicatorComponent: React.FC<
    AssessmentProgressIndicatorProps
> = ({ progressIndicatorId, step, totalSteps, progressBarValue }) => {
    const { formatMessage } = useIntl()

    return (
        <Stack gap={1} textAlign={'center'} py={1}>
            <CoreTypography variant={'overline'} id={progressIndicatorId}>
                {formatMessage(messages.currentStepHeader, {
                    currentStep: step,
                    totalSteps: totalSteps
                })}
            </CoreTypography>
            <Box my={1}>
                <LinearProgress
                    aria-labelledby={progressIndicatorId}
                    aria-hidden
                    variant={'determinate'}
                    value={progressBarValue}
                    sx={{
                        height: 8,
                        borderRadius: 4
                    }}
                />
            </Box>
        </Stack>
    )
}

const AssessmentProgressIndicator = React.memo(
    AssessmentProgressIndicatorComponent
)

export default AssessmentProgressIndicator
