import {
    store,
    userSetThriveTosAcceptedServerTime
} from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LoadingButton,
    type LoadingButtonProps
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, PropsWithChildren, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSetUserTncAcceptanceTimestampMutation } from '../../../../../graphql/generated/autogenerated'

export type SaveToSAcceptanceProps = Omit<
    LoadingButtonProps,
    'fixWidth' | 'onClick' | 'loading'
> & {
    saveLoading?: boolean
    onSaveToSAcceptance?: () => Promise<void>
    onSavedToSAcceptance?: (success: boolean) => Promise<void>
}

export const SaveToSAcceptance: React.FC<
    PropsWithChildren<SaveToSAcceptanceProps>
> = ({
    saveLoading,
    onSaveToSAcceptance: onSaveToSAcceptanceExternal,
    onSavedToSAcceptance,
    children,
    ...props
}) => {
    const [loading, setLoading] = useState(false)
    const [setUserTncAcceptanceTimestamp] =
        useSetUserTncAcceptanceTimestampMutation()

    const onSaveToSAcceptance = useCallback(() => {
        onSaveToSAcceptanceExternal?.()
        setLoading(true)
        setUserTncAcceptanceTimestamp()
            .then(async (data) => {
                if (data?.data?.identity?.user?.acceptToS?.serverTime) {
                    store.dispatch(
                        userSetThriveTosAcceptedServerTime(
                            data.data?.identity.user.acceptToS.serverTime
                        )
                    )
                    await onSavedToSAcceptance?.(true)
                } else {
                    await onSavedToSAcceptance?.(false)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [
        onSaveToSAcceptanceExternal,
        setUserTncAcceptanceTimestamp,
        onSavedToSAcceptance
    ])

    return (
        <LoadingButton
            color="primary"
            fixWidth={true}
            loading={loading || saveLoading}
            size="large"
            variant="contained"
            {...props}
            disabled={loading || saveLoading || props.disabled}
            onClick={onSaveToSAcceptance}
            data-testid="save-tos-button"
        >
            {children ?? (
                <CoreTypography customVariant="buttonLarge">
                    <FormattedMessage
                        defaultMessage="Save"
                        description="Accept the Privacy Policy and Terms of Service"
                    />
                </CoreTypography>
            )}
        </LoadingButton>
    )
}

export default memo(SaveToSAcceptance)
